.text-area{
    width: 100%;
    height: 100%;
    scroll-behavior:auto;
}

.hgt-full{
    height: 400px;
}


.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
  
  .badge-primary {
    color: #fff;
    background-color: #007bff;
  }
  
  .badge-secondary {
    color: #fff;
    background-color: #6c757d;
  }
  
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  
  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }
  
  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }
  
  .badge-dark {
    color: #fff;
    background-color: #343a40;
  }
  